@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Forum&display=swap');

* {
  font-family: "Poppins", sans-serif;
}
h1,h2,h3,h4,h5,h6{
  font-family: "Forum", serif;
}

:root {
  --primary-color: #FF7B00;
  --primary-lite-color: #FBF7B9;
  --secondary-color: #6F3F45;
  --white-color: #ffffff;
}

.primary-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  background-color: var(--primary-color);
  color: var(--white-color);
  transition: all 0.3s ease;
}

.App {
  background: linear-gradient(-45deg,var(--primary-lite-color) ,   var(--primary-lite-color),var(--white-color),var(--white-color));
  min-height: 100vh;
  background-size: 400% 400%; /* Adjust this size as per your preference */
  animation: gradientAnimation 15s ease infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}


.services-container {
  display: flex;
  justify-content: center;
}

.service-card {
  padding: 20px;
  border-radius: 20px;
  background-color: var(--primary-lite-color);
  color: var(--secondary-color);
  min-height: 280px;
  transition: all 0.3s ease;
}

.service-heading {
  font-size: 48px;
  font-weight: bold;
  text-align: center;
  color: var(--primary-color);
}

.home-heading {
  font-size: 42px;
  font-weight: bold;
  color: var(--primary-color);
}

.home-subheading {
  font-size: 28px;
  font-weight: bold;
  color: var(--primary-color);
}

.home-slogan {
  color: var(--secondary-color);
  font-size: 32px;
  font-weight: bold;
}

.home-description {
  color: var(--secondary-color);
  font-size: 16px;
}

.home-quote {
  background: var(--white-color);
  padding: 20px;
  border-radius: 20px;
}

.home-industry-card {
  background: var(--white-color);
  padding: 20px;
  border-radius: 20px;
  min-height: 425px;
  transition: all 0.3s ease;
}

.home-solutions-card {
  background: var(--primary-lite-color);
  padding: 20px;
  border-radius: 20px;
  min-height: 325px;
  transition: all 0.3s ease;
}

.home-industry {
  background: var(--primary-lite-color);
  padding: 20px;
  border-radius: 20px;
}

.home-solutions {
  background: var(--white-color);
  padding: 20px;
  border-radius: 20px;
}

.service-card:hover,
.home-industry-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  background: linear-gradient(to right, var(--white-color), var(--primary-lite-color));
  color: var(--primary-color);
}

.service-title {
  font-size: 28px;
}

.service-description {
  font-size: 16px;
  color: var(--secondary-color);

}



.aboutus-container,.home-country-card {
  text-align: center;
  background-color: var(--white-color);
  padding: 20px;
  border-radius: 20px;
  color: var(--secondary-color);
  min-height: 495px;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
}

.home-country-card{
  min-height: 525px;
}


.aboutus-container:hover,.home-country-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}

.aboutus-title {
  font-size: 24px;
}

.aboutus-button {
  border: none;
  background: none;
  text-decoration: underline;
  font-weight: bold;
  color: var(--primary-color);
}

.aboutus-calltoaction {
  text-align: center;
  font-weight: bold;
  color: var(--secondary-color);
}


.contactus-subtitle {
  font-size: 36px;
  font-weight: bold;
  color: var(--primary-color);
}

.contactus-description {
  font-size: 17px;
  color: var(--secondary-color)
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: var(--primary-color);
  color: var(--white-color);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1000;
  transition: all 0.3s ease;
}

.scroll-to-top:hover,
.primary-btn:hover {
  background-color: rgba(0, 0, 0, 0.1); 
  color: var(--secondary-color);
}

.footer-heading {
  font-size: 24px;
  color: var(--primary-color);
  font-weight: bold;
}

.footer-list {
  font-size: 14px;
  color: var(--secondary-color);
  text-decoration: none;
}

.footer-copyright {
  font-size: 12px;
  color: var(--secondary-color);
}

.home-solutions-image {
  border-radius: 20px;
}

.image-shadow{
  filter: drop-shadow(6px 6px 15px var(--primary-color));
}